<template>
  <!-- <div
    id="menuAluno"
    :class="`${$store.getters.getPerfil == 2 ? 'mt-75' : ''} h-100 `"
    :style="
      `background:${obj.cor_primaria};

    background: -moz-linear-gradient(45deg,${obj.cor_primaria} 0%, ${obj.cor_secundaria} 70%);

    background: -webkit-linear-gradient(45deg,${obj.cor_primaria} 0%,${obj.cor_secundaria} 17%);

    background: linear-gradient(45deg,${obj.cor_primaria} 0%, ${obj.cor_secundaria} 70%);
 
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='${obj.cor_primaria}', endColorstr='${obj.cor_secundaria}', GradientType=1);

    `
    "
  > -->
    <div
    id="menuAluno" :class="`${$store.getters.getPerfil == 2 ? 'mt-75' : ''} h-100 `">
    <div
      class="position-absolute"
      :style="
        `width: 10px; height: 100vh; right: -2px
        
        background:${obj.cor_primaria};

        background: -moz-linear-gradient(45deg,${obj.cor_primaria} 0%, ${obj.cor_secundaria} 70%);

        background: -webkit-linear-gradient(45deg,${obj.cor_primaria} 0%,${obj.cor_secundaria} 17%);

        background: linear-gradient(45deg,${obj.cor_primaria} 0%, ${obj.cor_secundaria} 70%);
    
        filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='${obj.cor_primaria}', endColorstr='${obj.cor_secundaria}', GradientType=1);

      `
      "
    ></div>

    <div
      v-if="$store.getters.getPerfil != 2"
      class="logoEscola d-flex align-items-center justify-content-center"
    >
      <div class="pt-5 d-flex flex-column">
        <img :src="obj.file" alt="schoolLogo.png" class="logo-perfil" />
        <small class="text-white tamanho-font mt-2">{{ obj.name }}</small>
      </div>
    </div>

    <div class="alunoMenuNav position-relative">
      <div
        class="identify d-flex align-items-center justify-content-center mb-2"
      >
        <div
          id="alunoPhotoPerfil"
          :style="
            `background-image: url(${
              getUserDatas.file
                ? getUserDatas.file
                : require('@/assets/aluno/perfilDefault.png')
            })`
          "
        ></div>
        <div
          :class="
            `dadosNivel d-flex justify-content-between ${
              $store.getters.getPerfil == 5 ? '' : 'my-px'
            }`
          "
          style="background: #fcaf66"
        >
          <div
            id="idStatusPerfil"
            class="px-2 d-flex align-items-center justify-content-center"
          >
            <p>
              {{ verifyStatus($store.getters.getPerfil) }}
              {{ userFirstName() }}
            </p>
          </div>
          <div
            v-if="$store.getters.getPerfil == 5"
            id="alunoNivel"
            class="d-flex align-items-center justify-content-center"
          >
            <small> {{ nivel }}</small>
          </div>
        </div>
        <div
          class="nivelIconClass"
          :style="
            `background-image: url(${require(`@/assets/aluno/icones-niveis/${imageIcon}`)}); border: 2px solid #fcaf66`
          "
          v-if="$store.getters.getPerfil == 5"
        ></div>
      </div>
      <nav>
        <ul class="mt-5 px-0">
          <!--
          <li
            v-if="$store.getters.getPerfil == 5"
            :class="`d-flex my-3 ${
              $route.path.match('/aluno/inicial') ? 'active-li-aluno' : null
            }`"
          >
            <div
              :style="`background-image: url('${require('@/assets/aluno/iconMenuNavInicial.png')}')`"
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="'/aluno/inicial'">
                <span class="text-uppercase color-0e5caf">inicial</span>
              </router-link>
            </div>
          </li>
          
          <li
            v-if="$store.getters.getPerfil == 5"
            :class="`d-flex my-3 ${
              $route.path == '/aluno/meus-temas/2' ? 'active-li-aluno' : null
            }`"
          >
            <div
              :style="`background-image: url('${require('@/assets/aluno/iconMenuNavInicial.png')}')`"
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="'/aluno/meus-temas/2'">
                <span class="text-uppercase color-0e5caf">Continuar</span>
              </router-link>
            </div>
          </li>
          -->
          <li
            v-if="$store.getters.getPerfil == 2"
            :class="
              `d-flex my-3 ${
                $route.path.match('inicial') ? 'active-li-aluno' : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('inicial') ? temasAzul : temasCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`/investidor/inicial`">
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('inicial')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >inicial</span
                >
              </router-link>
            </div>
          </li>
          <li
            v-if="$store.getters.getPerfil == 4"
            :class="
              `d-flex my-3 ${
                $route.path.match(`inicial`) ? 'active-li-aluno' : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('inicial') ? temasAzul : temasCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link
                :to="
                  `/${
                    $store.getters.getPerfil == 5
                      ? 'aluno/meus-temas/1'
                      : 'professor/inicial'
                  }`
                "
              >
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('inicial')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >inicial</span
                >
              </router-link>
            </div>
          </li>
          <li
            v-if="$store.getters.getPerfil == 3"
            :class="
              `d-flex my-3 ${
                $route.path.match(/(coordenador|inicial)/)
                  ? 'active-li-aluno'
                  : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match(
                    /(coordenador|inicial|unidades-e-instituicoes)/
                  )
                    ? progressoTurmasAzul
                    : progressoTurmasCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link
                v-if="$store.getters.getPerfil != 3"
                :to="
                  `/${verifyStatus(
                    $store.getters.getPerfil
                  ).toLowerCase()}/progresso-turmas`
                "
              >
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match(
                        /(coordenador|inicial|unidades-e-instituicoes)/
                      )
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >progresso turmas</span
                >
              </router-link>
              <router-link
                v-else
                :to="
                  `/${verifyStatus($store.getters.getPerfil).toLowerCase()}/${
                    $store.getters.getUserDatas.unidade_id
                  }/${$store.getters.getUnidadeUser}`
                "
              >
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match(
                        /(coordenador|inicial|unidades-e-instituicoes)/
                      )
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >progresso turmas</span
                >
              </router-link>
            </div>
          </li>
          <li
            v-if="$store.getters.getPerfil == 4"
            :class="
              `d-flex my-3 ${
                $route.path.match('unidades-e-instituicoes')
                  ? 'active-li-aluno'
                  : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('unidades-e-instituicoes')
                    ? iconUnidadesInstituicoesAzul
                    : iconUnidadesInstituicoesCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`/professor/unidades-e-instituicoes`">
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('unidades-e-instituicoes')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >Instituições</span
                >
              </router-link>
            </div>
          </li>
          <li
            v-if="$store.getters.getPerfil == 5"
            :class="
              `d-flex my-3 ${
                $route.path.match(`inicial`) ? 'active-li-aluno' : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('inicial') ? temasAzul : temasCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`/aluno/inicial`">
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('inicial')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >inicial</span
                >
              </router-link>
            </div>
          </li>
          <li
            v-if="$store.getters.getPerfil == 8"
            :class="
              `d-flex my-3 ${
                $route.path.match(`continuar`) ? 'active-li-aluno' : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${require('@/assets/aluno/iconMeusTemas.png')}')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="'/aluno/continuar-exercicio'">
                <span class="text-uppercase color-0e5caf">Continuar</span>
              </router-link>
            </div>
          </li>
          <li
            :class="
              `d-flex my-3 ${
                $route.path.match('minhas-conquistas') ? 'active-li-aluno' : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('minhas-conquistas')
                    ? iconMenuNavEstatistica
                    : iconMenuNavEstatisticaCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`/minhas-conquistas`">
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('minhas-conquistas')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >Conquistas</span
                >
              </router-link>
            </div>
          </li>
          <li
            :class="
              `d-flex my-3 ${
                $route.path.match('meus-dados') ? 'active-li-aluno' : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('meus-dados')
                    ? configuracoesAzul
                    : configuracoesCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`/meus-dados`">
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('meus-dados')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >meus dados</span
                >
              </router-link>
            </div>
          </li>
        </ul>
      </nav>
    </div>

    <footer id="footer">
      <div class="area-sair b-0">
        <div class="mb-2">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <button
          @click="desconectar()"
          class="text-uppercase bg-d42338 btn-style-default mt-2"
        >
          sair
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "alunoMenu",
  data() {
    return {
      nivel: null,
      imageIcon: "",
      temasAzul: require("@/assets/investidor/temasAzul.png"),
      temasCinza: require("@/assets/investidor/temasCinza.png"),
      configuracoesAzul: require("@/assets/investidor/configuracoesAzul.png"),
      configuracoesCinza: require("@/assets/investidor/configuracoesCinza.png"),
      progressoTurmasAzul: require("@/assets/icones/coordenador/progressoTurmasAzul.png"),
      progressoTurmasCinza: require("@/assets/icones/coordenador/progressoTurmasCinza.png"),
      iconMenuNavEstatistica: require("@/assets/investidor/iconMenuNavEstatistica.png"),
      iconMenuNavEstatisticaCinza: require("@/assets/investidor/iconMenuNavEstatisticaCinza.png"),
      iconUnidadesInstituicoesAzul: require("@/assets/investidor/iconUnidadesInstituicoesAzul.png"),
      iconUnidadesInstituicoesCinza: require("@/assets/investidor/iconUnidadesInstituicoesCinza.png"),
      obj: {
        name: "",
        file: require("../../assets/icones/iconeLogoTipo.png"),
        cor_primaria: "",
        cor_secundaria: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getUserDatas", "getUnidades"]),
  },
  mounted() {
    this.verificaHeight("header_0");
    this.getNivelAluno()
    this.pegaImg();
  },
  methods: {
   async getNivelAluno() {
      const response = await fetch(`${this.VUE_APP_CMS}api/users/getNivel`, {
        headers:  {
          Authorization: this.$store.getters.getPerfilToken,
        }
      })
      const responseJSON = await response.json()
      this.nivel = responseJSON.data.name
      this.imageIcon = responseJSON.data.image
    },
    pegaImg() {
      $.ajax({
        type: "GET",
        url: `${this.VUE_APP_CMS}api/unidades/${this.getUserDatas.unidade_id}`,
        dataType: "json",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          this.obj = response;
        },
        error: (response) => {},
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import "../../style/menuAlunoCoordenador.css";
ul li {
  opacity: 1 !important;
}

.mt-75 {
  margin-top: 10px;
}
.tamanho-font {
  font-size: 12px;
  margin-top: 5px;
}
.logo-perfil {
  width: 130px;
  height: 100px;
  object-fit: cover;
}
#footer {
  position: absolute;
  bottom: 15px;
  width: 100%;
}
#footer button {
  font-size: 12px;
  color: #fff;
}
</style>
