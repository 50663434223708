var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex",style:(_vm.$route.meta.showMenu == true
      ? !_vm.getOkShowContent ? '' : 'padding-top:60px'
      : ""),attrs:{"id":"app"}},[(!_vm.getOkShowContent && !_vm.$route.path.match('login') && _vm.$route.path != '/')?_c('div',{staticClass:"d-flex j-c-center position-absolute w-100 bg-42cc7e",staticStyle:{"z-index":"100000!important","height":"100vh"}},[_vm._m(0)]):_vm._e(),(_vm.getOkShowContent && !_vm.$route.path.match('login') && _vm.$route.path != '/')?_c('div',{staticClass:"col-md-2 pl-0",class:_vm.$route.path == '/login'
        ? 'd-none'
        : 'container ml-0 pl-0'},[(_vm.$route.meta.showMenu == true)?_c('div',[(_vm.$store.getters.getPerfil != 0 && _vm.$store.getters.getPerfil != 1)?_c('header',{attrs:{"id":"header_0"}},[(
            _vm.$store.getters.getPerfil != 0 && _vm.$store.getters.getPerfil != 1
          )?_c('EstudanteCoordenadorProfessorMenu'):_vm._e()],1):_vm._e(),(
          (_vm.$store.getters.getPerfil == 0) | (_vm.$store.getters.getPerfil == 1)
        )?_c('header',{staticClass:"theHeader",attrs:{"id":"header_0"}},[(
            (_vm.$store.getters.getPerfil == 0) | (_vm.$store.getters.getPerfil == 1)
          )?_c('AdminInvestidorMenu'):_vm._e()],1):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-md-10 p-0",class:_vm.$route.path == '/login' ? 'd-none' : _vm.$route.path == '/login/contato' ? 'col-md-12 p-0' : ''},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('div',{class:_vm.$route.meta.showMenu == true ? 'whereShowTheContent' : null},[_c('router-view',{key:_vm.$route.fullPath})],1)]),(_vm.getOkShowContent && _vm.$route.meta.showMenu)?_c('footer',{class:_vm.$route.meta.showMenu == false
          ? 'bg-222541'
          : _vm.$store.getters.getPerfil == 0 || _vm.$store.getters.getPerfil == 1
          ? 'bg-ebebeb'
          : 'bg-fff',attrs:{"id":"theFooter"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-md-6 mx-auto d-flex align-items-center justify-content-center pt-4 pb-3"},[(_vm.$route.meta.showMenu == true)?_c('img',{staticClass:"mr-3 logo",attrs:{"src":require("@/assets/logo.png"),"alt":"logo.png"}}):_vm._e(),(_vm.$route.path != '/login')?_c('div',{staticClass:"d-flex align-items-center justify-content-center"}):_vm._e(),(_vm.$route.path == '/login' || _vm.$route.path == '/login/contato')?_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-3"},[_vm._m(1),_vm._m(2)]):_vm._e()]),_c('p',{class:((_vm.$route.meta.showMenu == false ? 'color-fff' : 'color-0f5daf') + " py-3")},[_vm._v(" "+_vm._s(_vm.currentYear)+" © Todos os direitos reservados - Projeto Skaki Xadrez ")])])]):_vm._e()],1),(_vm.$route.path == '/login')?_c('div',[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('div',{class:_vm.$route.meta.showMenu == true ? 'whereShowTheContent' : null},[_c('router-view',{key:_vm.$route.fullPath})],1)]),(_vm.$route.path !== '/login')?_c('footer',{class:_vm.$route.meta.showMenu == false
          ? 'bg-222541'
          : _vm.$store.getters.getPerfil == 0 || _vm.$store.getters.getPerfil == 1
          ? 'bg-ebebeb'
          : 'bg-fff',attrs:{"id":"theFooter"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-md-6 mx-auto d-flex align-items-center justify-content-center pt-4 pb-3"},[(_vm.$route.meta.showMenu == true)?_c('img',{staticClass:"mr-3 logo",attrs:{"src":require("@/assets/logo.png"),"alt":"logo.png"}}):_vm._e(),(_vm.$route.path != '/login' )?_c('div',{staticClass:"d-flex align-items-center justify-content-center"}):_vm._e()])])]):_vm._e()],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"id":"theLogoImgOnLoad","src":require("@/assets/logo.png"),"alt":"logo.png"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hoverStyleButton"},[_c('a',{attrs:{"href":"http://","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"img-icons-footer-login",attrs:{"src":require("@/assets/icones/face.png"),"alt":"face.png"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-3 hoverStyleButton"},[_c('a',{attrs:{"href":"http://","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{staticClass:"img-icons-footer-login",attrs:{"src":require("@/assets/icones/insta.png"),"alt":"insta.png"}})])])}]

export { render, staticRenderFns }