import Vue from 'vue';
import router from './router';
import store from './store';

Vue.prototype.showModal = function(val) {
  this.$modal.show(val);
};
Vue.prototype.hideModal = function(val) {
  this.$modal.hide(val);
};

Vue.prototype.verificaArrow = function(val, val2) {
  if ($(`#${val}`).hasClass('show')) {
    $(`#${val2} img`).css({
      transform: 'rotateZ(0deg)',
    });
  } else {
    $(`#${val2} img`).css({
      transform: 'rotateZ(180deg)',
    });
  }
};

Vue.prototype.funcaoFiltroLimitaChars = function(value) {
  if (value.length > 35) {
    return `${value.substring(0, 25)}...`;
  }
  return value;
};

Vue.prototype.simpleHoverEffect = function(el) {
  $(el).css({
    display: 'block',
  });
};

Vue.prototype.hoverEfect = function(el) {
  $(el).fadeIn('fast', function() {
    $(el).css({
      display: 'block',
    });
  });
};

Vue.prototype.noHoverEfect = function(el) {
  $(el).fadeOut('fast', function() {
    $(el).css({
      display: 'none',
    });
  });
};

Vue.prototype.playSong = function(valSong) {
  var audio = new Audio(valSong);
  // audio.play()
};

Vue.prototype.userFirstName = function() {
  let name = store.getters.getUserDatas.name;
  let transformName = name.split(' ');
  return transformName[0];
};

Vue.prototype.userLastName = function() {
  let name = store.getters.getUserDatas.name.split(' ');
  let transformName = name[name.length - 1];
  return transformName;
};

(Vue.prototype.desconectar = function() {
  store.commit('resetAll');
  setTimeout(() => {
    router.push('/login');
  }, 1000);
}),
  (Vue.prototype.geradorSenhas = function() {
    // var chars = "0123456789abcdefghijklmnopqrstuvwxyz";
    var chars = '0123456789';
    var passwordLength = 6;
    var password = '';

    for (var i = 0; i < passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
  });

Vue.prototype.verifyStatus = function(valToVerify) {
  switch (valToVerify) {
    case 1:
      valToVerify = 'Administrador';
      break;
    case 2:
      valToVerify = 'Investidor';
      break;
    case 3:
      valToVerify = 'Coordenador';
      break;
    case 4:
      valToVerify = 'Professor';
      break;
    case 5:
      valToVerify = 'Aluno';
      break;

    default:
      valToVerify = '';
      break;
  }
  return valToVerify;
};

Vue.prototype.verifyImageToChoord = function(choordPiece, colorPiece) {
  let peca = null;
  let cor = colorPiece == 'black' ? 'Branco' : 'Preto';
  switch (choordPiece) {
    case null:
      peca = require(`../src/assets/icones/iconePeao${cor}.png`);
      break;

    case 'r':
      // peca = 'r'
      peca = require(`../src/assets/icones/iconeTorre${cor}.png`);
      break;

    case 'n':
      // peca = 'n'
      peca = require(`../src/assets/icones/iconeCavalo${cor}.png`);
      break;

    case 'b':
      // peca = 'b'
      peca = require(`../src/assets/icones/iconeBispo${cor}.png`);
      break;

    case 'k':
      // peca = 'k'
      peca = require(`../src/assets/icones/iconeRei${cor}.png`);
      break;

    case 'q':
      // peca = 'q'
      peca = require(`../src/assets/icones/iconeRainha${cor}.png`);
      break;

    default:
      break;
  }
  return peca;
};

Vue.prototype.nivelAlunoPeca = function(nivel) {
  let returnNivel = null;
  switch (nivel) {
    case 0:
      returnNivel = 'peão';
      break;

    case 1:
      returnNivel = 'cavalo';
      break;

    case 3:
      returnNivel = 'bispo';
      break;

    case 4:
      returnNivel = 'torre';
      break;

    case 5:
      returnNivel = 'rainha';
      break;

    case 6:
      returnNivel = 'rei';
      break;

    default:
      break;
  }
  return returnNivel;
};

Vue.prototype.geradorDeCodUsuario = function(tipoPerfil, cpf) {
  let valueRetorno = '';
  let dadosUser = this.$store.getters.getUserDatas;
  let val1 =
    tipoPerfil < 10
      ? `00${tipoPerfil}`
      : tipoPerfil < 100
      ? `0${tipoPerfil}`
      : '';
  let val2 =
    dadosUser.id < 10
      ? `00${dadosUser.id}`
      : dadosUser.id < 100
      ? `0${dadosUser.id}`
      : '';
  let val3 = '';
  let val4 = '';
  // let val3 = cpf.replace(/[^\d]+/g, "").substr(0, 2);
  // let val4 = cpf.replace(/[^\d]+/g, "").substr(9, 10);
  valueRetorno = `${val1}${val2}${val3}${val4}`;
  return valueRetorno;
};

Vue.prototype.funcaoVerificaLength = function(value) {
  if (value <= 0) {
    cod = '0001';
  } else {
    if (value < 10) {
      return `000${value}`;
    } else if (value < 100) {
      return `00${value}`;
    } else if (value < 1000) {
      return `0${value}`;
    }
  }
};

Vue.prototype.filtrosDePesquisaDados = function(
  stringPesquisa = [],
  advindoGetters,
) {
  let retornoPesquisa;
  let buscaDados = null;

  switch (advindoGetters) {
    case 'exercicios':
      buscaDados = store.getters.getTemasExercicios;
      break;
    case 'unidades':
      buscaDados = store.getters.getUnidades;
      break;
    case 'administrador':
      buscaDados = store.getters.getAdministradores;
      break;
    case 'investidor':
      buscaDados = store.getters.getInvestidores;
      break;
    case 'coordenador':
      buscaDados = store.getters.getCoordenadores;
      break;
    case 'professor':
      buscaDados = store.getters.getProfessores;
      break;
    case 'aluno':
      buscaDados = store.getters.getAlunos;
      break;
  }

  if (stringPesquisa.length == 0) {
    retornoPesquisa = buscaDados;
  } else {
    retornoPesquisa = buscaDados.filter((item) => {
      // let name = item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      // let categoria = item.tema_grupo.Name
      // let code = item.cod
      // let infoID = `${item.id}`
      // return name.toUpperCase().includes(stringPesquisa.toUpperCase()) || code.includes(stringPesquisa) || infoID.includes(stringPesquisa) || categoria.toUpperCase().includes(stringPesquisa.toUpperCase())
      let code = item.cod;
      let name = item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      let nameAuthor = item.user_name ? item.user_name : 'vazio';
      if (advindoGetters == 'exercicios') {
        let tema = item.tema_grupo.Name != null ? item.tema_grupo.Name : '';
        return (
          code.includes(stringPesquisa) ||
          name.toUpperCase().includes(stringPesquisa.toUpperCase()) ||
          tema.toUpperCase().includes(stringPesquisa.toUpperCase()) ||
          nameAuthor.toUpperCase().includes(stringPesquisa.toUpperCase())
        );
      } else {
        return (
          code.includes(stringPesquisa) ||
          name.toUpperCase().includes(stringPesquisa.toUpperCase())
        );
      }
    });
  }
  //console.log("pesquisa", retornoPesquisa)
  return retornoPesquisa;
};

Vue.prototype.filtrosDePesquisaDadosTurmas = function(stringPesquisa = []) {
  // var pesquisa = this.searchJuan;
  let retornoPesquisa;
  let buscaDados = store.getters.getTurmasLista;

  if (stringPesquisa.length == 0) {
    retornoPesquisa = buscaDados;
  } else {
    retornoPesquisa = buscaDados.filter((item) => {
      let code = item.cod;
      let professor = item.professor.name;
      return (
        code.includes(stringPesquisa) ||
        professor.toUpperCase().includes(stringPesquisa.toUpperCase())
      );
    });
  }
  return retornoPesquisa;
};

Vue.prototype.validador = function(meta, perfil) {
  let retorno = false;
  meta.forEach((element) => {
    if (element == perfil) {
      retorno = true;
    }
  });
  return retorno;
};

Vue.prototype.verificaHeight = function(elId) {
  let theWindowHeight = window.innerHeight;

  if (document.getElementById(elId)) {
    document.getElementById(elId).style.height = `${theWindowHeight}px`;
  }
};
// Toast Global
Vue.prototype.toastGlobal = function(type, msg) {
  Vue.$toast.open({
    message: msg,
    type: type,
    duration: 2500,
  });
};

Vue.prototype.codeToStudent = function(val) {
  let toReturn = [];
  val.forEach((element) => {
    switch (element.toUpperCase()[1]) {
      case 'A':
        toReturn.push('01');
        break;
      case 'B':
        toReturn.push('02');
        break;
      case 'C':
        toReturn.push('03');
        break;
      case 'D':
        toReturn.push('04');
        break;
      case 'E':
        toReturn.push('05');
        break;
      case 'F':
        toReturn.push('06');
        break;
      case 'G':
        toReturn.push('07');
        break;
      case 'H':
        toReturn.push('08');
        break;
      case 'I':
        toReturn.push('09');
        break;
      case 'J':
        toReturn.push('10');
        break;
      case 'K':
        toReturn.push('11');
        break;
      case 'L':
        toReturn.push('12');
        break;
      case 'M':
        toReturn.push('13');
        break;
      case 'N':
        toReturn.push('14');
        break;
      case 'O':
        toReturn.push('15');
        break;
      case 'P':
        toReturn.push('16');
        break;
      case 'Q':
        toReturn.push('17');
        break;
      case 'R':
        toReturn.push('18');
        break;
      case 'S':
        toReturn.push('19');
        break;
      case 'value':
        toReturn.push('20');
        break;
      case 'T':
        toReturn.push('21');
        break;
      case 'U':
        toReturn.push('22');
        break;
      case 'V':
        toReturn.push('23');
        break;
      case 'W':
        toReturn.push('24');
        break;
      case 'X':
        toReturn.push('25');
        break;
      case 'Y':
        toReturn.push('26');
        break;
      case 'Z':
        toReturn.push('27');
        break;

      default:
        break;
    }
  });
  if (val.length <= 3) {
    let theRandom = Math.round(Math.random() * 1000);
    toReturn.push(
      `${theRandom < 10 ? '0' : ''}${Math.round(Math.random() * 100)}`,
    );
  }
  return toReturn;
};

Vue.prototype.alunoNameToUppercase = function(dado) {
  let el1 = dado.split(' ')[0].split('');
  let el2 = el1[0].toUpperCase();
  el1.splice(0, 1);
  el1.unshift(el2);
  return el1.join().replaceAll(',', '');
};
