var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:((_vm.$store.getters.getPerfil == 2 ? 'mt-75' : '') + " h-100 "),attrs:{"id":"menuAluno"}},[_c('div',{staticClass:"position-absolute",style:(("width: 10px; height: 100vh; right: -2px\n      \n      background:" + (_vm.obj.cor_primaria) + ";\n\n      background: -moz-linear-gradient(45deg," + (_vm.obj.cor_primaria) + " 0%, " + (_vm.obj.cor_secundaria) + " 70%);\n\n      background: -webkit-linear-gradient(45deg," + (_vm.obj.cor_primaria) + " 0%," + (_vm.obj.cor_secundaria) + " 17%);\n\n      background: linear-gradient(45deg," + (_vm.obj.cor_primaria) + " 0%, " + (_vm.obj.cor_secundaria) + " 70%);\n  \n      filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='" + (_vm.obj.cor_primaria) + "', endColorstr='" + (_vm.obj.cor_secundaria) + "', GradientType=1);\n\n    "))}),(_vm.$store.getters.getPerfil != 2)?_c('div',{staticClass:"logoEscola d-flex align-items-center justify-content-center"},[_c('div',{staticClass:"pt-5 d-flex flex-column"},[_c('img',{staticClass:"logo-perfil",attrs:{"src":_vm.obj.file,"alt":"schoolLogo.png"}}),_c('small',{staticClass:"text-white tamanho-font mt-2"},[_vm._v(_vm._s(_vm.obj.name))])])]):_vm._e(),_c('div',{staticClass:"alunoMenuNav position-relative"},[_c('div',{staticClass:"identify d-flex align-items-center justify-content-center mb-2"},[_c('div',{style:(("background-image: url(" + (_vm.getUserDatas.file
              ? _vm.getUserDatas.file
              : require('@/assets/aluno/perfilDefault.png')) + ")")),attrs:{"id":"alunoPhotoPerfil"}}),_c('div',{class:("dadosNivel d-flex justify-content-between " + (_vm.$store.getters.getPerfil == 5 ? '' : 'my-px')),staticStyle:{"background":"#fcaf66"}},[_c('div',{staticClass:"px-2 d-flex align-items-center justify-content-center",attrs:{"id":"idStatusPerfil"}},[_c('p',[_vm._v(" "+_vm._s(_vm.verifyStatus(_vm.$store.getters.getPerfil))+" "+_vm._s(_vm.userFirstName())+" ")])]),(_vm.$store.getters.getPerfil == 5)?_c('div',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"id":"alunoNivel"}},[_c('small',[_vm._v(" "+_vm._s(_vm.nivel))])]):_vm._e()]),(_vm.$store.getters.getPerfil == 5)?_c('div',{staticClass:"nivelIconClass",style:(("background-image: url(" + (require(("@/assets/aluno/icones-niveis/" + _vm.imageIcon))) + "); border: 2px solid #fcaf66"))}):_vm._e()]),_c('nav',[_c('ul',{staticClass:"mt-5 px-0"},[(_vm.$store.getters.getPerfil == 2)?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match('inicial') ? 'active-li-aluno' : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('inicial') ? _vm.temasAzul : _vm.temasCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/investidor/inicial"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('inicial')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("inicial")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 4)?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match("inicial") ? 'active-li-aluno' : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('inicial') ? _vm.temasAzul : _vm.temasCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":("/" + (_vm.$store.getters.getPerfil == 5
                    ? 'aluno/meus-temas/1'
                    : 'professor/inicial'))}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('inicial')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("inicial")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 3)?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match(/(coordenador|inicial)/)
                ? 'active-li-aluno'
                : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match(
                  /(coordenador|inicial|unidades-e-instituicoes)/
                )
                  ? _vm.progressoTurmasAzul
                  : _vm.progressoTurmasCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[(_vm.$store.getters.getPerfil != 3)?_c('router-link',{attrs:{"to":("/" + (_vm.verifyStatus(
                  _vm.$store.getters.getPerfil
                ).toLowerCase()) + "/progresso-turmas")}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match(
                      /(coordenador|inicial|unidades-e-instituicoes)/
                    )
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("progresso turmas")])]):_c('router-link',{attrs:{"to":("/" + (_vm.verifyStatus(_vm.$store.getters.getPerfil).toLowerCase()) + "/" + (_vm.$store.getters.getUserDatas.unidade_id) + "/" + (_vm.$store.getters.getUnidadeUser))}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match(
                      /(coordenador|inicial|unidades-e-instituicoes)/
                    )
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("progresso turmas")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 4)?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match('unidades-e-instituicoes')
                ? 'active-li-aluno'
                : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('unidades-e-instituicoes')
                  ? _vm.iconUnidadesInstituicoesAzul
                  : _vm.iconUnidadesInstituicoesCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/professor/unidades-e-instituicoes"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('unidades-e-instituicoes')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("Instituições")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 5)?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match("inicial") ? 'active-li-aluno' : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('inicial') ? _vm.temasAzul : _vm.temasCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/aluno/inicial"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('inicial')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("inicial")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 8)?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match("continuar") ? 'active-li-aluno' : null))},[_c('div',{style:(("background-image: url('" + (require('@/assets/aluno/iconMeusTemas.png')) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":'/aluno/continuar-exercicio'}},[_c('span',{staticClass:"text-uppercase color-0e5caf"},[_vm._v("Continuar")])])],1)]):_vm._e(),_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match('minhas-conquistas') ? 'active-li-aluno' : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('minhas-conquistas')
                  ? _vm.iconMenuNavEstatistica
                  : _vm.iconMenuNavEstatisticaCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/minhas-conquistas"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('minhas-conquistas')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("Conquistas")])])],1)]),_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match('meus-dados') ? 'active-li-aluno' : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('meus-dados')
                  ? _vm.configuracoesAzul
                  : _vm.configuracoesCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/meus-dados"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('meus-dados')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("meus dados")])])],1)])])])]),_c('footer',{attrs:{"id":"footer"}},[_c('div',{staticClass:"area-sair b-0"},[_vm._m(0),_c('button',{staticClass:"text-uppercase bg-d42338 btn-style-default mt-2",on:{"click":function($event){return _vm.desconectar()}}},[_vm._v(" sair ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":""}})])}]

export { render, staticRenderFns }