import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import $ from 'jquery';

Vue.use(Router);

function guards(guards) {
  return async (to, from, next) => {
    // a flag to discard remaining guards
    let changed = false;

    // handle next for multiple guards
    const mNext = function(value) {
      // prevent calling next after it is already resolved with a value
      if (changed) return;

      // if we have 'value' reslove next with the value and set changed flag
      if (typeof value != 'undefined') {
        changed = true;
        next(value);
      }
    };

    // call guards
    for (let i = 0; i < guards.length; i++) {
      if (changed) break;
      await guards[i](to, from, mNext);
    }

    // move on if none of guards resolved next with a value
    if (!changed) next();
  };
}

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

function validaUser(to, from, next) {
  next();
}

export default new Router({
  mode: 'history',

  routes: [
    {
      path: '/login/contato',
      name: 'LoginContato',
      component: lazyLoad('LoginContato'),
      meta: { showMenu: false },
    },
    {
      path: '/',
      name: 'main',
      component: lazyLoad('Login'),
      meta: { showMenu: false },
      redirect: { name: 'Login' },
    },
    {
      path: '/login',
      name: 'Login',
      component: lazyLoad('Login'),
      meta: { showMenu: false },
    },
    // {
    //     path: '/aluno/inicial',
    //     name: 'Aluno_Inicial',
    //     component: lazyLoad('Aluno_Inicial'),
    // beforeEnter: validaUser
    // },
    {
      path:
        '/aluno/continuar-exercicio/:temaGrupoExercicio/:temaExercicio/:idExercicio/:indiceStore/:indiceExercicio',
      name: 'AlunoContinuarExercicio1',
      component: lazyLoad('AlunoContinuarExercicio'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [5] },
    },

    {
      path: '/aluno/inicial',
      name: 'Aluno_Meus_Temas',
      component: lazyLoad('Aluno_Meus_Temas'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1, 3, 4, 5] },
    },
    {
      path:
        '/:typeOfPerfil/unidades-e-instituicoes/:idunidade/:unidade/:idclass/:class/statusAluno/:idAluno/:nameAluno',
      name: 'Aluno_Meus_Temas_Administradores',
      component: lazyLoad('Aluno_Meus_Temas'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1, 3, 4] },
    },
    {
      path:
        '/:typeOfPerfil/unidades-e-instituicoes/:idunidade/:unidade/:idclass/:class/statusAluno/:idAluno/:nameAluno/:temaGrupoExercicio/:temaExercicio/:idExercicio/:indiceStore',
      name: 'AlunoContinuarExercicio',
      component: lazyLoad('AlunoContinuarExercicio'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1, 3, 4] },
    },
    {
      path: '/professor/inicial',
      name: 'ProfessorMeusTemas',
      component: lazyLoad('ProfessorMeusTemas'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [4] },
    },
    {
      path: '/meus-dados',
      name: 'MeusDados',
      component: lazyLoad('MeusDados'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true },
    },
    {
      path: '/minhas-conquistas',
      name: 'MinhasConquistas',
      component: lazyLoad('MinhasConquistas'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true },
    },
    {
      path: '/:typeOfPerfil/progresso-turmas',
      name: 'Coordenador_Progresso_Turmas_Tela_1',
      component: lazyLoad('Coordenador_Progresso_Turmas_Tela_1'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1, 3, 4] },
    },
    // {
    //     path: '/coordenador/inicial',
    //     name: 'Coordenador_Progresso_Turmas_Tela_1',
    //     component: lazyLoad('Coordenador_Progresso_Turmas_Tela_1'),
    //     beforeEnter: guards([validaUser]),
    //     meta: { showMenu: true, typePerfilVerificaRouta: }
    // },
    {
      path: '/investidor/inicial',
      name: 'InvestidorAdmInicial',
      component: lazyLoad('InvestidorEstatistica'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1, 2] },
    },
    {
      path: '/investidor/estatistica',
      name: 'InvestidorEstatistica',
      component: lazyLoad('InvestidorEstatistica'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [2] },
    },
    // {
    //     path: '/administrador/inicial',
    //     name: 'InvestidorAdmInicial',
    //     component: lazyLoad('InvestidorAdmInicial'),
    //     beforeEnter: guards([validaUser]),
    //     meta: {showMenu: true, typePerfilVerificaRouta:}

    // },
    {
      path: '/administrador/professores',
      name: 'AdministradorTelaProfessores',
      component: lazyLoad('AdministradorTelaProfessores'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1] },
    },
    //Mudar rota e criar telas
    {
      path: '/administrador/alunos',
      name: 'AdministradorTelaUsuarios',
      component: lazyLoad('AdministradorTelaUsuarios'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1] },
    },
    //fim de mudar telas
    {
      path: '/administrador/administradores',
      name: 'AdministradorTelaAdministradores',
      component: lazyLoad('AdministradorTelaAdministradores'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1] },
    },
    {
      path: '/administrador/Coordenadores',
      name: 'AdministradorTelaCoordenadores',
      component: lazyLoad('AdministradorTelaCoordenadores'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1] },
    },
    {
      path: '/administrador/investidores',
      name: 'AdministradorTelaInvestidores',
      component: lazyLoad('AdministradorTelaInvestidores'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1] },
    },
    {
      path: '/administrador/unidades-e-instituicoes',
      alias: ['/professor/unidades-e-instituicoes'],
      name: 'AdministradorTelaInstituicoes',
      component: lazyLoad('AdministradorTelaInstituicoes'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1, 3, 4] },
    },
    {
      path: '/administrador/unidades-e-instituicoes/:idunidade/:unidade',
      alias: [
        '/coordenador/:idunidade/:unidade',
        '/professor/:idunidade/:unidade',
      ],
      name: 'AdministradorTelaVisualizarInstituicoes',
      component: lazyLoad('AdministradorTelaVisualizarInstituicoes'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1, 3, 4] },
    },
    {
      path:
        '/administrador/unidades-e-instituicoes/:idunidade/:unidade/:idclass/:class/',
      alias: [
        '/coordenador/unidades-e-instituicoes/:idunidade/:unidade/:idclass/:class/',
        '/professor/unidades-e-instituicoes/:idunidade/:unidade/:idclass/:class/',
      ],
      name: 'AdministradorTelaVisualizarAlunos',
      component: lazyLoad('AdministradorTelaVisualizarAlunos'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1, 3, 4] },
    },
    {
      path:
        '/administrador/unidades-e-instituicoes/:idunidade/:unidade/:idclass/:class/:idAluno/:nameAluno/:NumeroTentativa',
      alias: [
        '/coordenador/unidades-e-instituicoes/:idunidade/:unidade/:idclass/:class/:idAluno/:nameAluno/:NumeroTentativa',
        '/professor/unidades-e-instituicoes/:idunidade/:unidade/:idclass/:class/:idAluno/:nameAluno/:NumeroTentativa',
      ],
      name: 'AdministradorTelaVisualizarAlunosExercicio',
      component: lazyLoad('AdministradorTelaVisualizarAlunos'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1, 3, 4] },
    },
    {
      path: '/administrador/inicial',
      name: 'AdministradorEstatistica',
      component: lazyLoad('InvestidorEstatistica'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1] },
    },
    {
      path: '/administrador/temas/:pagePagination',
      name: 'AdministradorMeusTemas',
      component: lazyLoad('ProfessorMeusTemas'),
      beforeEnter: guards([validaUser]),
      meta: { showMenu: true, typePerfilVerificaRouta: [1] },
    },
    // {
    //     path: '/administrador/configuracoes',
    //     name: 'AdministradorConfiguracoes',
    //     component: lazyLoad('AdministradorConfiguracoes'),
    //     beforeEnter: guards([validaUser]),
    //     meta: { showMenu: true, typePerfilVerificaRouta: []}

    // },
  ],

  scrollBehavior: (o) =>
    o.params.tabId ||
    $('html, body').animate({ scrollTop: 0 }, { duration: 'slow' }),
});
