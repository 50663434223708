const VUE_APP_CMS = 'https://api.skaki.com.br/';
export default {
  state: {
    codigoDaUltimaUnidade: null,
    unidades: [],
  },
  mutations: {
    SET_UNIDADES(state, val) {
      state.unidades = val;
    },
    SET_CODIGO_UNIDADE(state, val) {
      state.codigoDaUltimaUnidade = val;
    },
  },
  actions: {
    getUnidadesDB({ commit, state }, obj) {
      let cod = null;
      return new Promise((resolve) => {
        $.ajax({
          type: 'GET',
          url: `${VUE_APP_CMS}api/unidades`,
          dataType: 'json',
          headers: {
            Authorization: obj.token,
          },
          success: (response) => {
            commit('SET_UNIDADES', response[0]);
            let DataLength = response[0];
            if (DataLength <= 0) {
              cod = '0001';
            } else {
              if (DataLength < 10) {
                cod = `000${DataLength + 1}`;
              } else if (DataLength < 100) {
                cod = `00${DataLength + 1}`;
              } else if (DataLength < 1000) {
                cod = `0${DataLength + 1}`;
              }
            }
            commit('SET_CODIGO_UNIDADE', cod);
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },
    deletePutUnidadesDB({ dispatch }, obj) {
      let cod = null;
      return new Promise((resolve) => {
        $.ajax({
          type: obj.type,
          url: `${VUE_APP_CMS}api/unidades/${obj.id}`,
          data: obj.type == 'PUT' ? obj.dadosEdit : '',
          dataType: 'json',
          headers: {
            Authorization: obj.token,
          },
          success: (response) => {
            dispatch('getUnidadesDB', {
              token: obj.token,
            });
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },
  },
  getters: {
    getUnidades(state) {
      return state.unidades;
    },
    getPegaImg(state) {
      return function() {
        return state.unidades.filter(file === unidade.id);
      };
    },
    getCodigoDaUltimaUnidade(state) {
      return state.codigoDaUltimaUnidade;
    },
  },
};
