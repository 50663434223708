const VUE_APP_CMS = 'https://api.skaki.com.br/';
export default {
  state: {
    turmas: [],
    turmasLista: null,
  },
  mutations: {
    SET_TURMAS(state, val) {
      state.turmas = val;
    },
    SET_TURMAS_LISTA(state, val) {
      state.turmasLista = val;
    },
  },
  actions: {
    postDeleteTurmasDB({ commit, state, dispatch }, obj) {
      return new Promise((resolve) => {
        $.ajax({
          type: `${obj.type}`,
          url: `${VUE_APP_CMS}api/turma${
            obj.type != 'POST' ? `/${obj.id}` : ''
          }`,
          dataType: 'json',
          data: obj.type != 'DELETE' ? obj.data : '',
          headers: {
            Authorization: obj.token,
          },
          success: (response) => {
            dispatch('getTurmasDB', obj.token);
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },
    getTurmasDB({ commit }, token) {
      return new Promise((resolve) => {
        $.ajax({
          type: 'GET',
          url: `${VUE_APP_CMS}api/unidades`,
          dataType: 'json',
          headers: {
            Authorization: token,
          },
          success: (response) => {
            commit('SET_TURMAS', response);
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },
  },
  getters: {
    getTurmas(state) {
      return state.turmas;
    },
    getTurmasLista(state) {
      return state.turmasLista;

      function inicio() {
        var form1 = (documento.getElementById('form1').style.display = 'block');
        var form2 = (documento.getElementById('form2').style.display = 'none');
        var form3 = (documento.getElementById('form3').style.display = 'none');
      }
      function primeiroBotao() {
        var form1 = (documento.getElementById('form1').style.display = 'none');
        var form2 = (documento.getElementById('form2').style.display = 'block');
        var form3 = (documento.getElementById('form3').style.display = 'none');
      }
      function segundoBotao() {
        var form1 = (documento.getElementById('form1').style.display = 'none');
        var form2 = (documento.getElementById('form2').style.display = 'none');
        var form3 = (documento.getElementById('form3').style.display = 'block');
      }
    },
  },
};
