const VUE_APP_CMS = 'https://api.skaki.com.br/';
export default {
  // exercicions
  // 0 livre
  // 1 pgn
  state: {
    dadosDash: {},
  },
  mutations: {
    SET_DASHBOARD_DATA(state, val) {
      state.dadosDash = val;
    },
  },
  actions: {
    getDatasDashBoard({ commit }, token) {
      return new Promise((resolve) => {
        $.ajax({
          type: 'GET',
          url: `${VUE_APP_CMS}api/dashboard`,
          dataType: 'json',
          headers: {
            Authorization: token,
          },
          success: (response) => {
            resolve({ valid: true, msg: response });
            commit('SET_DASHBOARD_DATA', response[0]);
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },
  },
  getters: {
    DatasDashBoard(state) {
      return state.dadosDash;
    },
  },
};
