<template>
  <div
    id="app"
    class="d-flex"
    :style="
      $route.meta.showMenu == true
        ? !getOkShowContent ? '' : 'padding-top:60px'
        : ``
    "
  >
    <div class="d-flex j-c-center position-absolute w-100 bg-42cc7e" style="z-index: 100000!important; height: 100vh" v-if="!getOkShowContent && !$route.path.match('login') && $route.path != '/'">
      <div>
        <img
          id="theLogoImgOnLoad"
          src="@/assets/logo.png"
          alt="logo.png"
        />
      </div>
    </div>
    <div
    v-if="getOkShowContent && !$route.path.match('login') && $route.path != '/'"
      class="col-md-2 pl-0"
      :class="
        $route.path == '/login'
          ? 'd-none'
          : 'container ml-0 pl-0'
      "
    >
      <div v-if="$route.meta.showMenu == true">
        <!--header alunos-->
        <header
          id="header_0"
          v-if="$store.getters.getPerfil != 0 && $store.getters.getPerfil != 1"
        >
          <EstudanteCoordenadorProfessorMenu
            v-if="
              $store.getters.getPerfil != 0 && $store.getters.getPerfil != 1
            "
          />
        </header>
        <!--fim header aluno-->
        <header
          class="theHeader"
          id="header_0"
          v-if="
            ($store.getters.getPerfil == 0) | ($store.getters.getPerfil == 1)
          "
        >
          <AdminInvestidorMenu
            v-if="
              ($store.getters.getPerfil == 0) | ($store.getters.getPerfil == 1)
            "
          />
        </header>
      </div>
    </div>
    <div class="col-md-10 p-0" :class="$route.path == '/login' ? 'd-none' : $route.path == '/login/contato' ? 'col-md-12 p-0' : ''">
      <transition name="slide-fade" mode="out-in">
        <div
          :class="$route.meta.showMenu == true ? 'whereShowTheContent' : null"
        >
          <router-view :key="$route.fullPath"/>
        </div>
      </transition>
      <footer
        v-if="getOkShowContent && $route.meta.showMenu"
        id="theFooter"
        :class="
          $route.meta.showMenu == false
            ? 'bg-222541'
            : $store.getters.getPerfil == 0 || $store.getters.getPerfil == 1
            ? 'bg-ebebeb'
            : 'bg-fff'
        "
      >
        <div class="container">
          <div
            class="col-md-6 mx-auto d-flex align-items-center justify-content-center pt-4 pb-3"
          >
            <img
              v-if="$route.meta.showMenu == true"
              src="@/assets/logo.png"
              alt="logo.png"
              class="mr-3 logo"
            />
            <div
              class="d-flex align-items-center justify-content-center"
              v-if="$route.path != '/login'"
            >
              <!-- <div
                class="div-rede-social bg-0e5caf d-flex align-items-center justify-content-center mx-2"
              >
                <i class="fab fa-facebook-f color-fff"></i>
              </div> -->
              <!-- <div
                class="div-rede-social bg-0e5caf d-flex align-items-center justify-content-center mx-2"
              >
                <i class="fab fa-instagram color-fff"></i>
              </div> -->
            </div>
            <div
              class="d-flex align-items-center justify-content-center mt-3"
              v-if="$route.path == '/login' || $route.path == '/login/contato'"
            >
              <div class="hoverStyleButton">
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  <img
                    class="img-icons-footer-login"
                    src="@/assets/icones/face.png"
                    alt="face.png"
                  />
                </a>
              </div>
              <div class="ml-3 hoverStyleButton">
                <a href="http://" target="_blank" rel="noopener noreferrer">
                  <img
                    class="img-icons-footer-login"
                    src="@/assets/icones/insta.png"
                    alt="insta.png"
                  />
                </a>
              </div>
            </div>
          </div>
          <p
            :class="
              `${
                $route.meta.showMenu == false ? 'color-fff' : 'color-0f5daf'
              } py-3`
            "
          >
            {{ currentYear }} © Todos os direitos reservados - Projeto Skaki
            Xadrez
          </p>
        </div>
      </footer>
    </div>
    <div v-if="$route.path == '/login'">
      <transition name="slide-fade" mode="out-in">
        <div
          :class="$route.meta.showMenu == true ? 'whereShowTheContent' : null"
        >
          <router-view :key="$route.fullPath"/>
        </div>
      </transition>
      <footer
        v-if="$route.path !== '/login'"
        id="theFooter"
        :class="
          $route.meta.showMenu == false
            ? 'bg-222541'
            : $store.getters.getPerfil == 0 || $store.getters.getPerfil == 1
            ? 'bg-ebebeb'
            : 'bg-fff'
        "
      >
        <div class="container">
          <div
            class="col-md-6 mx-auto d-flex align-items-center justify-content-center pt-4 pb-3"
          >
            <img
              v-if="$route.meta.showMenu == true"
              src="@/assets/logo.png"
              alt="logo.png"
              class="mr-3 logo"
            />
            <div
              class="d-flex align-items-center justify-content-center"
              v-if="$route.path != '/login' "
            >
              <!-- <div
                class="div-rede-social bg-0e5caf d-flex align-items-center justify-content-center mx-2"
              >
                <i class="fab fa-facebook-f color-fff"></i>
              </div> -->
              <!-- <div
                class="div-rede-social bg-0e5caf d-flex align-items-center justify-content-center mx-2"
              >
                <i class="fab fa-instagram color-fff"></i>
              </div> -->
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import EstudanteCoordenadorProfessorMenu from "./components/menus/EstudanteCoordenadorProfessorMenu.vue";
import AdminInvestidorMenu from "./components/menus/AdminInvestidorMenu.vue";
import {mapGetters} from 'vuex'
export default {
  name: "App",
  data() {
    return {
      bg_login: require("@/assets/bg-login.png"),
      currentYear: new Date().getFullYear(),
      myStorage: window.localStorage
    };
  },
  components: {
    EstudanteCoordenadorProfessorMenu,
    AdminInvestidorMenu,
  },
  computed:{
    ...mapGetters(['getOkShowContent'])
  },
  
};
</script>

<style>
@import "./style/default.css";

#app {
  text-align: center;
}

.logo {
  width: 130px;
}

.img-icons-footer-login {
  width: 35px;
}
.padrao-icon {
  width: 15px !important;
  height: 15px !important;
}

.scaleLess{
  transform: scale('.7')
}

</style>
