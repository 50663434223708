const VUE_APP_CMS = 'https://api.skaki.com.br/';
export default {
  state: {
    statusExercicio: 1,
    dadosExercicioPaginacao: [],
  },
  mutations: {
    SET_STATUS_EXERCICIO(state, val) {
      state.statusExercicio = val;
    },
    SET_DATA_EXERCICIOS_PAGINACAO(state, val) {
      state.dadosExercicioPaginacao = val;
    },
  },
  actions: {},
  getters: {
    getStatusExercicio(state) {
      return state.statusExercicio;
    },
    getDadosExercicioPaginacao(state) {
      return state.dadosExercicioPaginacao;
    },
  },
};
