const VUE_APP_CMS = 'https://api.skaki.com.br/';
import $ from 'jquery';
// const VUE_APP_TOKEN= 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjcGYiOiIxNDYxNDQwNjYxMCIsInByb2ZpbGVJZCI6MSwidXNlcklkIjoxLCJ1bmlkYWRlSWQiOjF9.g8_HFruhaOXr8sNh4QyP29GJe-soG-msUgmNjU3ggxM'
export default {
  // 1 - ADMINISTRADOR Ok
  // 2 - INVETIDOR OK
  // 3 - COORDENADOR Ok
  // 4 - PROFESSOR Ok
  // 5 - ALUNO Ok
  state: {
    typePerfil: null,
    tokenPerfil: null,
    idPerfil: null,
    userLogin: {},
    unidadeUser: '',
    administradores: [],
    coordenadores: [],
    professores: [],
    investidores: [],
    alunos: [],
    ediInvestAdmin: {},
    okShowContent: false,
  },
  mutations: {
    SET_USER_LOGIN(state, val) {
      state.userLogin = val;
    },
    SET_REMOVE_USER(state) {
      state.usuario = {};
    },
    SET_USER_LOGIN_PERFIL(state, val) {
      state.typePerfil = val;
    },
    SET_USER_LOGIN_ID(state, val) {
      state.idPerfil = val;
    },
    SET_USER_LOGIN_TOKEN(state, val) {
      state.tokenPerfil = val;
    },
    SET_USERtheAdministradorES(state, val) {
      state.administradores = val;
    },
    SET_USER_COORDDENADORES(state, val) {
      state.coordenadores = val;
    },
    SET_USER_PROFESSORES(state, val) {
      state.professores = val;
    },
    SET_USER_INVESTIDORES(state, val) {
      state.investidores = val;
    },
    SET_USER_ALUNOS(state, val) {
      state.alunos = val;
    },
    SET_ADMININVEST_TO_EDIT(state, val) {
      state.ediInvestAdmin = val;
    },
    SET_UNIDADE_USER(state, val) {
      state.unidadeUser = val;
    },
    SET_OK_SHOW_CONTENT(state, val) {
      state.okShowContent = val;
    },
  },
  actions: {
    login({ dispatch }, postObj) {
      return new Promise((resolve, reject) => {
        $.ajax({
          async: true,
          type: 'POST',
          url: `${VUE_APP_CMS}api/login`,
          data: postObj,
          dataType: 'json',
          headers: {},
          success: (response) => {
            dispatch('buscaDadosUsuarioById', {
              id: response.user_id,
              token: response.acess_token,
            });
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },
    buscaDadosUsuarioById({ commit, state, getters }, objIdToken) {
      commit('SET_USER_LOGIN_TOKEN', objIdToken.token);
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'GET',
          url: `${VUE_APP_CMS}api/users/${objIdToken.id}`,
          dataType: 'json',
          headers: {
            Authorization: objIdToken.token,
          },
          success: (response) => {
            commit('SET_USER_LOGIN', response);
            commit('SET_USER_LOGIN_ID', objIdToken.id);
            commit('SET_USER_LOGIN_PERFIL', response.profile_id);
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },
    desconectar({ state }) {
      state.userLogin = {};
    },
    getDadosDeTodosUsuarios({ commit }, token) {
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'GET',
          url: `${VUE_APP_CMS}api/users`,
          data: null,
          dataType: 'json',
          headers: {
            Authorization: token,
          },
          success: (response) => {
            let administradores = [];
            let coordenadores = [];
            let professores = [];
            let investidores = [];
            let alunos = [];
            response.forEach((element) => {
              if (element.profile_id == 1) {
                administradores.push(element);
              } else if (element.profile_id == 2) {
                investidores.push(element);
              } else if (element.profile_id == 3) {
                coordenadores.push(element);
              } else if (element.profile_id == 4) {
                professores.push(element);
              } else if (element.profile_id == 5) {
                alunos.push(element);
              }
              commit('SET_USERtheAdministradorES', administradores);
              commit('SET_USER_COORDDENADORES', coordenadores);
              commit('SET_USER_PROFESSORES', professores);
              commit('SET_USER_INVESTIDORES', investidores);
              commit('SET_USER_ALUNOS', alunos);
            });
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            reject({ valid: false, msg: response });
          },
        });
      });
    },
    delUser({ dispatch, getters }, objToEdit) {
      return new Promise((resolve, reject) => {
        $.ajax({
          type: `${objToEdit.typeAjax}`,
          url: `${VUE_APP_CMS}api/users/${objToEdit.cod}`,
          dataType: 'json',
          headers: {
            Authorization: getters.getPerfilToken,
          },
          success: (response) => {
            dispatch('getDadosDeTodosUsuarios', objToEdit.token);
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            reject({ valid: false, msg: response });
          },
        });
      });
    },
    editUser({ dispatch, getters }, objToEdit) {
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'PUT',
          url: `${VUE_APP_CMS}api/users/${objToEdit.id}`,
          data: objToEdit,
          dataType: 'json',
          headers: {
            Authorization: getters.getPerfilToken,
          },
          success: (response) => {
            dispatch('buscaDadosUsuarioById', {
              token: getters.getPerfilToken,
              id: objToEdit.id,
            });
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            reject({ valid: false, msg: response });
          },
        });
      });
    },
  },
  getters: {
    getPerfil(state) {
      return state.typePerfil;
    },
    getPerfilToken(state) {
      return state.tokenPerfil;
    },
    getUserDatas(state) {
      return state.userLogin;
    },
    getAdministradores(state) {
      return state.administradores;
    },
    getCoordenadores(state) {
      return state.coordenadores;
    },
    getInvestidores(state) {
      return state.investidores;
    },
    getAdminInvest(state) {
      return state.ediInvestAdmin;
    },
    getProfessores(state) {
      return state.professores;
    },
    getAlunos(state) {
      return state.alunos;
    },
    getUnidadeUser(state) {
      return state.unidadeUser;
    },
    getOkShowContent(state) {
      return state.okShowContent;
    },
  },
};
