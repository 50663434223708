const VUE_APP_CMS = 'https://api.skaki.com.br/';
import storeUser from './user';
import $ from 'jquery';
export default {
  // exercicions
  // 0 livre
  // 1 pgn
  state: {
    typeExercicio: 0,
    iniciarGravacao: false,
    statusJogadasPGN: false,
    codigoPGN: [],
    codigoPGNresolucao: [],
    codigoFEN: null,
    dadosExercicios: {},
    temaID: null,
    exercicioID: null,
    paginationExercicios: null,
    listaExercicios: [],
    codExercicios: null,
    lengthExercicios: null,
    dadosTema: {},
    codigoFENparaResolucao: [],
    categoriasTemas: [],
    meutabuleiro: [],
    stockThinking: false,
    tentativasOK: false,
    editarDadosExercicio: {},
    exerciciosModal: [],
    fenPromovePeao: null,
    nextExercicio: false,
    exercicioPendente: null,
    exercicioConluido: null,
    bloquearTabuleiro: null,
    situacaoGameStock: null,
    listaExerciciosModal: [],
  },
  mutations: {
    RESET_CAMPOS(state) {
      (state.iniciarGravacao = false),
        (state.codigoPGN = []),
        (state.codigoPGNresolucao = []),
        (state.codigoFEN = null),
        (state.codigoFENparaResolucao = []),
        (state.categoriasTemas = []),
        (state.meutabuleiro = []);
    },
    SET_TYPE_EXERCICIO(state, val) {
      state.typeExercicio = val;
    },
    SET_FEN_PROMOCAO_PEAO(state, val) {
      state.fenPromovePeao = val;
    },
    SET_STATUS_GRAVACAO_EXERCICIO(state, val) {
      state.iniciarGravacao = val;
    },
    SET_CODIGOFEN(state, val) {
      state.codigoFENparaResolucao = val;
    },
    SET_CODIGOPGN(state, val) {
      state.codigoPGN = val;
    },
    SET_LIMPAPGN(state) {
      state.iniciarGravacao = false;
      state.codigoPGN = [];
    },
    SET_DADOS_EXERCICIOS(state, val) {
      state.dadosExercicios = val;
    },
    SET_TEMA_ID(state, val) {
      state.temaID = val;
    },
    SET_EXERCICIO_ID(state, val) {
      state.exercicioID = val;
    },
    SET_USER_LOGIN_TOKEN(state, val) {
      state.tokenPerfil = val;
    },
    SET_USER_LOGIN_TOKEN_EXERCICIO(state, val) {
      state.tokenPerfil = val;
    },
    SET_EXERCICIOS(state, val) {
      state.listaExercicios = val;
    },
    SET_COD_EXERCICIO(state, val) {
      state.codExercicios = val;
    },
    SET_COD_EXERCICIO_LENGTH(state, val) {
      state.lengthExercicios = val;
    },
    SET_DADOS_TEMA(state, val) {
      state.dadosTema = val;
    },
    SET_CODIGOPGN_RESOLUCAO(state, val) {
      state.codigoPGNresolucao = val;
    },
    SET_CATEGORIAS(state, val) {
      state.categoriasTemas = val;
    },
    SET_MEU_TABULEIRO(state, val) {
      state.meutabuleiro = val;
    },
    SET_STATUS_ALUNO_PGN(state, val) {
      state.statusJogadasPGN = val;
    },
    SET_STOCK_THINK(state, val) {
      state.stockThinking = val;
    },
    SET_TENTATIVAS(state, val) {
      state.tentativasOK = val;
    },
    SET_EDITA_EXERCICIO(state, val) {
      state.editarDadosExercicio = val;
    },
    SET_PAGINATE_EXERCICIO(state, val) {
      state.paginationExercicios = val;
    },
    SET_EXERCICIOS_MODAL(state, val) {
      state.exerciciosModal = val;
    },
    SET_NEXT_EXERCICIO(state, val) {
      state.nextExercicio = val;
    },
    SET_EXERCICIO_PENDENTE(state, val) {
      state.exercicioPendente = val;
    },
    SET_TENTATIVAS_EXERCICIOS(state, val) {
      state.tentativasExercicios = val;
    },
    SET_EXERCICIO_CONCLUIDO(state, val) {
      state.exercicioConluido = val;
    },
    SET_BLOQUEAR_TABULEIRO(state, val) {
      state.bloquearTabuleiro = val;
    },
    SET_SITUACAO_GAME_STOCK(state, val) {
      state.situacaoGameStock = val;
    },
    SET_MODAL_LISTA_EXERCICIOS(state, val) {
      state.listaExerciciosModal = val;
    },
  },
  actions: {
    resetCamposStore({ commit }) {
      commit('RESET_CAMPOS');
    },
    //começa a listagem dos temas

    getTemas({ commit, dispatch }, obj) {
      let objToGet = [];
      return new Promise((resolve) => {
        $.ajax({
          type: 'GET',
          url: `${VUE_APP_CMS}api/tema`,
          data: null,
          dataType: 'json',
          headers: {
            Authorization: obj.token,
          },
          success: (response) => {
            console.log('chamou tema');
            objToGet.push(response);
            commit('SET_EXERCICIOS', response);
            commit('SET_PAGINATE_EXERCICIO', response.last_page);
            dispatch('getCategoriasExercicios', obj.token);
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },

    //fim listar temas

    getCategoriasExercicios({ commit, getters }, token) {
      return new Promise((resolve) => {
        $.ajax({
          type: 'GET',
          url: `${VUE_APP_CMS}api/temaGrupo`,
          data: null,
          dataType: 'json',
          headers: {
            Authorization: token,
          },
          success: (response) => {
            commit('SET_CATEGORIAS', response.data);
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },
    editTemas({ state }, token) {
      return new Promise((resolve) => {
        $.ajax({
          type: 'PUT',
          url: `${VUE_APP_CMS}api/tema/${state.dadosTema.idTema}`,
          data: state.dadosTema,
          dataType: 'json',
          headers: {
            Authorization: token,
            // "Content-Type": "application/x-www-form-urlencoded"
          },
          success: (response) => {
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },
    excluirTema({ dispatch }, objToDelete) {
      return new Promise((resolve) => {
        $.ajax({
          type: 'DELETE',
          url: `${VUE_APP_CMS}api/tema/${objToDelete.id}`,
          data: null,
          dataType: 'json',
          headers: {
            Authorization: objToDelete.token,
            // "Content-Type": "application/x-www-form-urlencoded"
          },
          success: (response) => {
            resolve({ valid: true, msg: response });
            dispatch('getTemas', objToDelete);
            //    location.reload()
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },
    excluirExercicio({ state, dispatch }, token) {
      return new Promise((resolve) => {
        $.ajax({
          type: 'DELETE',
          url: `${VUE_APP_CMS}api/exercicios/${state.exercicioID}`,
          data: null,
          dataType: 'json',
          headers: {
            Authorization: token,
            // "Content-Type": "application/x-www-form-urlencoded"
          },
          success: (response) => {
            let obj = { token };
            dispatch('getTemas', obj);
            resolve({ valid: true, msg: response });
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
          },
        });
      });
    },
  },
  getters: {
    getSituacaoGameStock(state) {
      return state.situacaoGameStock;
    },
    getExercicio(state) {
      return state.typeExercicio;
    },
    getStatusGravacao(state) {
      return state.iniciarGravacao;
    },
    getCodigoFEN(state) {
      return state.codigoFEN;
    },
    getcodigoPGN(state) {
      return state.codigoPGN;
    },
    getDadosExercicios(state) {
      return state.dadosExercicios;
    },
    getTemaID(state) {
      return state.temaID;
    },
    getTemasExercicios(state) {
      return state.listaExercicios;
    },
    getCodExercicioEmQuestao(state) {
      return state.codExercicios;
    },
    getCodigoGeradoNovoExercicio(state) {
      return `${state.codExercicios}-${state.lengthExercicios}`;
    },
    getDadosTemas(state) {
      return state.dadosTema;
    },
    getcodigoFENparaResolucao(state) {
      return state.codigoFENparaResolucao;
    },
    getCategorias(state) {
      return state.categoriasTemas;
    },
    getCodigoPGNresolucao(state) {
      return state.codigoPGNresolucao;
    },
    getMeuTabuleiro(state) {
      return state.meutabuleiro;
    },
    getStatusAlunoPGN(state) {
      return state.statusJogadasPGN;
    },
    getStockThinking(state) {
      return state.stockThinking;
    },
    getTentativasOK(state) {
      return state.tentativasOK;
    },
    getEditarDadosExercicio(state) {
      return state.editarDadosExercicio;
    },
    getPaginationExercicios(state) {
      return state.paginationExercicios;
    },
    getExerciciosModal(state) {
      return state.exerciciosModal;
    },
    getFenProcaoPeao(state) {
      return state.fenPromovePeao;
    },
    getNextExercicio(state) {
      return state.nextExercicio;
    },
    getExercicioPendente(state) {
      return state.exercicioPendente;
    },
    getTentativasExercicios(state) {
      return state.tentativasExercicios >= 3 ? true : false;
    },
    getExercicioConcluido(state) {
      return state.exercicioConluido >= 2 ? true : false;
    },
    getBloquearTabuleiro(state) {
      return state.bloquearTabuleiro;
    },
    getListaExerciciosModal(state) {
      return state.listaExerciciosModal;
    },
  },
};
