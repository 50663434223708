import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import user from './user'
import exercicios from './exercicios'
import unidades from './unidades'
import turmas from './turmas'
import dashboard from './dashBoard'
import resolucaoExercicio from './resolucaoExercicio'

Vue.use(Vuex)
    const vuexPersist = new VuexPersist({
        key: 'skaki',
        storage: localStorage
    })
    let initialState = {
        exercicios:exercicios.state,
        user:user.state,
    }

export default new Vuex.Store({
    plugins: [
        vuexPersist.plugin
    ],
    mutations: {
        resetAll(state) {
            Object.keys(state).forEach(key => {
                Object.assign(state[key], initialState[key])
            })
        },
    },
    modules: {
        user,
        exercicios,
        unidades,
        turmas,
        dashboard,
        resolucaoExercicio
    }
})