<template>
  <div id="menuInvestidor" class="pt-3">
    <div class="logo-in-menu d-flex align-items-center justify-content-center">
      <div>
        <img src="@/assets/logo.png" alt="logo.png" />
      </div>
    </div>

    <div class="alunoMenuNav position-relative">
      <div class="identify2 d-flex mb-2 ml-4">
        <div
          id="alunoPhotoPerfil"
          :style="
            `background-image: url(${
              getUserDatas.file
                ? getUserDatas.file
                : require('@/assets/aluno/perfilDefault.png')
            })`
          "
        ></div>
        <div class="dadosNivel2 d-flex justify-content-between">
          <div
            id="idStatusPerfil"
            class="ml-2 px-2 d-flex align-items-center justify-content-center"
          >
            <p class="color-fff">
              {{ verifyStatus($store.getters.getPerfil) }} Skaki
            </p>
          </div>
          <div
            v-if="$store.getters.getPerfil == 5"
            id="alunoNivel"
            class="d-flex align-items-center justify-content-center"
          >
            <small>Nível Peão </small>
          </div>
        </div>
        <div
          class="nivelIconClass"
          :style="
            `background-image: url(${require('@/assets/aluno/peao.png')}); border: 2px solid #fcaf66`
          "
          v-if="$store.getters.getPerfil == 5"
        ></div>
      </div>
      <nav>
        <ul class="mt-5 px-0 position-relative" style="height: 55vh">
          <li
            v-if="$store.getters.getPerfil == 2"
            :class="
              `d-flex my-3 ${
                $route.path.match('/investidor/inicial')
                  ? 'active-li-aluno active-color-investidor'
                  : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('/investidor/inicial')
                    ? inicialAzul
                    : inicialCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="'/investidor/inicial'">
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('inicial')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >inicial</span
                >
              </router-link>
            </div>
          </li>

          <li
            v-if="$store.getters.getPerfil == 2"
            :class="
              `d-flex my-3 ${
                $route.path.match('/investidor/Estatistica')
                  ? 'active-li-aluno active-color-investidor'
                  : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('/investidor/Estatistica')
                    ? estatisticaAzul
                    : estatisticaCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="'/investidor/Estatistica'">
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('Estatistica')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >estatística</span
                >
              </router-link>
            </div>
          </li>
          <li
            v-if="$store.getters.getPerfil == 1"
            :class="
              `d-flex my-3 ${
                $route.path.match('inicial') ? 'active-li-aluno' : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('inicial') ? temasAzul : temasCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`/administrador/inicial`">
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('inicial')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >inicial</span
                >
              </router-link>
            </div>
          </li>
          <li
            v-if="$store.getters.getPerfil == 1"
            :class="
              `d-flex my-3 ${
                $route.path.match('temas') ? 'active-li-aluno' : ''
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('temas') ? temasAzul : temasCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`/administrador/temas/1`">
                <!-- <router-link :to="{ name: 'AdministradorMeusTemas' }"> -->

                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('temas')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >temas</span
                >
              </router-link>
            </div>
          </li>
          <!--Ajustes Fabio para acordion-->
          <li
            v-if="$store.getters.getPerfil == 1"
            id="usuarios"
            :class="
              `d-flex my-3 flex-column ${
                $route.path.match('administradores') ? 'active-li-aluno' : null
              }`
            "
          >
            <section class="d-flex align-items-center">
              <div
                :style="
                  `background-image: url('${
                    $route.path.match(
                      /(administradores|professores|coordenadores|investidores|alunos)/
                    )
                      ? iconProfessoresAzul
                      : iconProfessoresCinza
                  }')`
                "
                class="imagem-header"
              ></div>
              <div
                href="#user"
                class="font-weight-bold"
                data-toggle="collapse"
                aria-expanded="false"
                @click="expandMenu()"
              >
                <router-link :to="`/administrador/administradores`">
                  <span
                    :class="
                      `text-uppercase ${
                        $route.path.match(
                          /(administradores|professores|coordenadores|investidores|alunos)/
                        )
                          ? 'color-0e5daf color-cbccd3'
                          : 'color-cbccd3'
                      }`
                    "
                  >
                    usuários
                  </span>
                </router-link>
              </div>
            </section>
            <ul
              :class="expand ? 'd-block' : ''"
              class="collapse list-unstyled ml-4 bg-backgrond position-relative"
              id="user"
            >
              <li
                v-if="$store.getters.getPerfil == 1"
                :class="
                  `d-flex my-1 ${
                    $route.path.match('administradores')
                      ? 'active-li-subItem'
                      : null
                  }`
                "
              >
                <div
                  class="item-drop"
                  :style="
                    `background-image: url('${
                      $route.path.match('administradores')
                        ? iconSubItemUserEscuro
                        : iconSubItemUserClaro
                    }')`
                  "
                ></div>
                <div class="d-flex align-items-center justify-content-center">
                  <router-link :to="`/administrador/administradores`">
                    <span
                      :class="
                        `text-uppercase ${
                          $route.path.match('administradores')
                            ? 'color-0e5daf'
                            : 'color-b2d8ff'
                        }`
                      "
                    >
                      administradores
                    </span>
                  </router-link>
                </div>
              </li>
              <li
                v-if="$store.getters.getPerfil == 1"
                :class="
                  `d-flex my-1 ${
                    $route.path.match('investidores')
                      ? 'active-li-subItem'
                      : null
                  }`
                "
              >
                <div
                  class="item-drop"
                  :style="
                    `background-image: url('${
                      $route.path.match('investidores')
                        ? iconSubItemUserEscuro
                        : iconSubItemUserClaro
                    }')`
                  "
                ></div>
                <div class="d-flex align-items-center justify-content-center">
                  <router-link :to="`/administrador/investidores`">
                    <span
                      :class="
                        `text-uppercase ${
                          $route.path.match('investidores')
                            ? 'color-0e5daf'
                            : 'color-b2d8ff'
                        }`
                      "
                    >
                      investidores
                    </span>
                  </router-link>
                </div>
              </li>
              <li
                v-if="$store.getters.getPerfil == 1"
                :class="
                  `d-flex my-1 ${
                    $route.path.match('coordenadores')
                      ? 'active-li-subItem'
                      : null
                  }`
                "
              >
                <div
                  class="item-drop"
                  :style="
                    `background-image: url('${
                      $route.path.match('coordenadores')
                        ? iconSubItemUserEscuro
                        : iconSubItemUserClaro
                    }')`
                  "
                ></div>
                <div class="d-flex align-items-center justify-content-center">
                  <router-link :to="`/administrador/coordenadores`">
                    <span
                      :class="
                        `text-uppercase ${
                          $route.path.match('coordenadores')
                            ? 'color-0e5daf'
                            : 'color-b2d8ff'
                        }`
                      "
                    >
                      coordenadores
                    </span>
                  </router-link>
                </div>
              </li>
              <li
                v-if="$store.getters.getPerfil == 1"
                :class="
                  `d-flex my-1 ${
                    $route.path.match('professores')
                      ? 'active-li-subItem'
                      : null
                  }`
                "
              >
                <div
                  class="item-drop"
                  :style="
                    `background-image: url('${
                      $route.path.match('professores')
                        ? iconSubItemUserEscuro
                        : iconSubItemUserClaro
                    }')`
                  "
                ></div>
                <div class="d-flex align-items-center justify-content-center">
                  <router-link :to="`/administrador/professores`">
                    <span
                      :class="
                        `text-uppercase ${
                          $route.path.match('professores')
                            ? 'color-0e5daf'
                            : 'color-b2d8ff'
                        }`
                      "
                    >
                      professores
                    </span>
                  </router-link>
                </div>
              </li>
              <li
                v-if="$store.getters.getPerfil == 1"
                :class="
                  `d-flex my-1 ${
                    $route.path.match('alunos') ? 'active-li-subItem' : null
                  }`
                "
              >
                <div
                  class="item-drop"
                  :style="
                    `background-image: url('${
                      $route.path.match('alunos')
                        ? iconSubItemUserEscuro
                        : iconSubItemUserClaro
                    }')`
                  "
                ></div>
                <div class="d-flex align-items-center justify-content-center">
                  <router-link :to="`/administrador/alunos`">
                    <span
                      :class="
                        `text-uppercase ${
                          $route.path.match('alunos')
                            ? 'color-0e5daf'
                            : 'color-b2d8ff'
                        }`
                      "
                    >
                      alunos
                    </span>
                  </router-link>
                </div>
              </li>
            </ul>
          </li>

          <li
            v-if="$store.getters.getPerfil == 1"
            :class="
              `d-flex my-3 ${
                $route.path.match('unidades-e-instituicoes')
                  ? 'active-li-aluno'
                  : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('unidades-e-instituicoes')
                    ? iconUnidadesInstituicoesAzul
                    : iconUnidadesInstituicoesCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`/administrador/unidades-e-instituicoes`">
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('unidades-e-instituicoes')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >Instituições</span
                >
              </router-link>
            </div>
          </li>
          <!--
          <li
            v-if="$store.getters.getPerfil == 1"
            :class="`d-flex my-3 ${
              $route.path.match('configuracoes') ? 'active-li-aluno' : null
            }`"
          >
            <div
              :style="`background-image: url('${
                $route.path.match('configuracoes')
                  ? configuracoesAzul
                  : configuracoesCinza
              }')`"
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`/administrador/configuracoes`">
                <span
                  :class="`text-uppercase ${
                    $route.path.match('configuracoes') ? 'color-0e5daf' : 'color-cbccd3'
                  }`"
                  >configurações</span
                >
              </router-link>
            </div>
          </li>
          -->
          <li
            v-if="
              $store.getters.getPerfil == 1 || $store.getters.getPerfil == 2
            "
            :class="
              `d-flex my-3 ${
                $route.path.match('meus-dados') ? 'active-li-aluno' : null
              }`
            "
          >
            <div
              :style="
                `background-image: url('${
                  $route.path.match('meus-dados')
                    ? configuracoesAzul
                    : configuracoesCinza
                }')`
              "
            ></div>
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`/meus-dados`">
                <span
                  :class="
                    `text-uppercase ${
                      $route.path.match('meus-dados')
                        ? 'color-0e5daf'
                        : 'color-cbccd3'
                    }`
                  "
                  >meus dados</span
                >
              </router-link>
            </div>
          </li>
          <li id="footer">
            <button
              @click="desconectar()"
              class="text-uppercase bg-d42338 color-fff btn-style-default mt-2"
            >
              sair
            </button>
          </li>
        </ul>
      </nav>
    </div>
    <!-- <div class="area-sair position-relative"> -->
    <!-- <div class="area-sair ">
      <div
        class="mb-2"
        v-if="$store.getters.getPerfil != 0 && $store.getters.getPerfil != 1"
      >
        <img src="@/assets/logo.png" alt="" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "alunoMenu",
  data() {
    return {
      inicialAzul: require("@/assets/investidor/iconMenuNavInicial.png"),
      estatisticaAzul: require("@/assets/investidor/iconMenuNavEstatistica.png"),
      inicialCinza: require("@/assets/investidor/iconMenuNavInicialCinza.png"),
      configuracoesAzul: require("@/assets/investidor/configuracoesAzul.png"),
      configuracoesCinza: require("@/assets/investidor/configuracoesCinza.png"),
      estatisticaCinza: require("@/assets/investidor/iconMenuNavEstatisticaCinza.png"),
      iconProfessoresAzul: require("@/assets/investidor/iconProfessoresAzul.png"),
      iconProfessoresCinza: require("@/assets/investidor/iconProfessoresCinza.png"),
      iconSubItemUserClaro: require("@/assets/icones/iconSubItemUserClaro.png"),
      iconSubItemUserEscuro: require("@/assets/icones/iconSubItemUserEscuro.png"),
      iconUnidadesInstituicoesAzul: require("@/assets/investidor/iconUnidadesInstituicoesAzul.png"),
      iconUnidadesInstituicoesCinza: require("@/assets/investidor/iconUnidadesInstituicoesCinza.png"),
      temasAzul: require("@/assets/investidor/temasAzul.png"),
      temasCinza: require("@/assets/investidor/temasCinza.png"),
      expand: false,
    };
  },
  computed: {
    ...mapGetters(["getUserDatas"]),
  },
  mounted() {
    this.verificaHeight("header_1");
  },
  methods: {
    expandMenu() {
      this.expand = !this.expand
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import "../../style/menuAlunoCoordenador.css";

ul li {
  opacity: 1 !important;
}

#usuarios:hover #other-options-usuarios {
  display: block;
}

#other-options-usuarios {
  display: none;
  top: -20px;
  left: -12px;
  border-radius: 15px;
}

.item-drop {
  margin: 0 15px 0 0 !important;
}

/*accordion*/
.m-80 {
  margin-left: -90px;
}

.imagem-header {
  width: 25px !important;
  height: 30px !important;
  object-fit: cover;
  margin: 0 25px;
}
.bg-backgrond {
  background-color: rgba(35, 38, 68, 0.9);
}
#footer {
  /* position: absolute; */
  margin-top: 80px;
  bottom: 15px;
  width: 100%;
}
#footer button {
  font-size: 12px;
  color: #fff;
}
</style>
