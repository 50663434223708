import Vue from 'vue';
import App from './App.vue';
import VueHead from 'vue-head';
import VueResource from 'vue-resource';
import router from './router';
import store from './store';
import validate from './validate';
import constantes from './const';
import vueTippy from 'vue-tippy';
import Multiselect from 'vue-multiselect';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import VueSimpleAlert from 'vue-simple-alert';
// import $ from 'jquery'
import vmodal from 'vue-js-modal';
import VueTheMask from 'vue-the-mask';
import VueCurrencyFilter from 'vue-currency-filter';
import movable from 'v-movable';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Paginate from 'vuejs-paginate';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueResource);
Vue.use(VueHead, { separator: '|', complement: 'Skaki' });
Vue.use(VueToast);
Vue.use(Multiselect);
Vue.use(vueTippy);
Vue.use(VueTheMask);
Vue.use(VueSimpleAlert);
Vue.use(movable);
Vue.use(vmodal);
// Vue.use($)
// Vue.use(VueCurrencyFilter, {
//     symbol: 'R$',
//     thousandsSeparator: '.',
//     fractionCount: 2,
//     fractionSeparator: ',',
//     symbolPosition: 'front',
//     symbolSpacing: true
// })
Vue.component('paginate', Paginate);
Vue.component('Cropper', Cropper);
Vue.mixin({
  data: function() {
    return {
      VUE_APP_CMS: 'https://api.skaki.com.br/',
    };
  },
});

Vue.filter('trataDataHora', function(value) {
  let valor = value.split('-', 3);
  valor.splice(1, 0, valor[2].split('T', 1));
  return `${valor[1]}/${valor[2]}/${valor[0]}`;
}),
  Vue.filter('trataCPF', function(value) {
    let valToReturn = value.split('');
    valToReturn.splice(3, 0, '.');
    valToReturn.splice(7, 0, '.');
    valToReturn.splice(11, 0, '-');
    return valToReturn.toString().replace(/[,]+/g, '');
  }),
  Vue.filter('verificaLength', function(value) {
    if (value <= 0) {
      cod = '0001';
    } else {
      if (value < 10) {
        return `000${value}`;
      } else if (value < 100) {
        return `00${value}`;
      } else if (value < 1000) {
        return `0${value}`;
      }
    }
  }),
  Vue.filter('filtroLimitaChars', function(value) {
    if (value.length > 35) {
      return `${value.substring(0, 35)}...`;
    }
    return value;
  }),
  (Vue.config.productionTip = false);

new Vue({
  render: (h) => h(App),
  router,
  store,
  methods: {
    fullscreenBoard: function() {
      if (
        document.querySelector('.board') &&
        document.body.clientHeight < document.body.clientWidth &&
        document.body.clientWidth > 768
      ) {
        var offset = 0;
        if (
          !document.querySelector('.menu-logo').classList.contains('is-hidden')
        ) {
          offset += 70;
        }
        document.querySelector('.board').style.width =
          document.body.clientHeight -
          document.querySelector('.menu').clientHeight -
          offset +
          'px';
      }
    },
  },
  mounted() {
    window.history.forward(1);
  },
}).$mount('#app');
