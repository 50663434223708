var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-3",attrs:{"id":"menuInvestidor"}},[_vm._m(0),_c('div',{staticClass:"alunoMenuNav position-relative"},[_c('div',{staticClass:"identify2 d-flex mb-2 ml-4"},[_c('div',{style:(("background-image: url(" + (_vm.getUserDatas.file
              ? _vm.getUserDatas.file
              : require('@/assets/aluno/perfilDefault.png')) + ")")),attrs:{"id":"alunoPhotoPerfil"}}),_c('div',{staticClass:"dadosNivel2 d-flex justify-content-between"},[_c('div',{staticClass:"ml-2 px-2 d-flex align-items-center justify-content-center",attrs:{"id":"idStatusPerfil"}},[_c('p',{staticClass:"color-fff"},[_vm._v(" "+_vm._s(_vm.verifyStatus(_vm.$store.getters.getPerfil))+" Skaki ")])]),(_vm.$store.getters.getPerfil == 5)?_c('div',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"id":"alunoNivel"}},[_c('small',[_vm._v("Nível Peão ")])]):_vm._e()]),(_vm.$store.getters.getPerfil == 5)?_c('div',{staticClass:"nivelIconClass",style:(("background-image: url(" + (require('@/assets/aluno/peao.png')) + "); border: 2px solid #fcaf66"))}):_vm._e()]),_c('nav',[_c('ul',{staticClass:"mt-5 px-0 position-relative",staticStyle:{"height":"55vh"}},[(_vm.$store.getters.getPerfil == 2)?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match('/investidor/inicial')
                ? 'active-li-aluno active-color-investidor'
                : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('/investidor/inicial')
                  ? _vm.inicialAzul
                  : _vm.inicialCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":'/investidor/inicial'}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('inicial')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("inicial")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 2)?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match('/investidor/Estatistica')
                ? 'active-li-aluno active-color-investidor'
                : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('/investidor/Estatistica')
                  ? _vm.estatisticaAzul
                  : _vm.estatisticaCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":'/investidor/Estatistica'}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('Estatistica')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("estatística")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 1)?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match('inicial') ? 'active-li-aluno' : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('inicial') ? _vm.temasAzul : _vm.temasCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/administrador/inicial"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('inicial')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("inicial")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 1)?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match('temas') ? 'active-li-aluno' : ''))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('temas') ? _vm.temasAzul : _vm.temasCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/administrador/temas/1"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('temas')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("temas")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 1)?_c('li',{class:("d-flex my-3 flex-column " + (_vm.$route.path.match('administradores') ? 'active-li-aluno' : null)),attrs:{"id":"usuarios"}},[_c('section',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"imagem-header",style:(("background-image: url('" + (_vm.$route.path.match(
                    /(administradores|professores|coordenadores|investidores|alunos)/
                  )
                    ? _vm.iconProfessoresAzul
                    : _vm.iconProfessoresCinza) + "')"))}),_c('div',{staticClass:"font-weight-bold",attrs:{"href":"#user","data-toggle":"collapse","aria-expanded":"false"},on:{"click":function($event){return _vm.expandMenu()}}},[_c('router-link',{attrs:{"to":"/administrador/administradores"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match(
                        /(administradores|professores|coordenadores|investidores|alunos)/
                      )
                        ? 'color-0e5daf color-cbccd3'
                        : 'color-cbccd3'))},[_vm._v(" usuários ")])])],1)]),_c('ul',{staticClass:"collapse list-unstyled ml-4 bg-backgrond position-relative",class:_vm.expand ? 'd-block' : '',attrs:{"id":"user"}},[(_vm.$store.getters.getPerfil == 1)?_c('li',{class:("d-flex my-1 " + (_vm.$route.path.match('administradores')
                    ? 'active-li-subItem'
                    : null))},[_c('div',{staticClass:"item-drop",style:(("background-image: url('" + (_vm.$route.path.match('administradores')
                      ? _vm.iconSubItemUserEscuro
                      : _vm.iconSubItemUserClaro) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/administrador/administradores"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('administradores')
                          ? 'color-0e5daf'
                          : 'color-b2d8ff'))},[_vm._v(" administradores ")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 1)?_c('li',{class:("d-flex my-1 " + (_vm.$route.path.match('investidores')
                    ? 'active-li-subItem'
                    : null))},[_c('div',{staticClass:"item-drop",style:(("background-image: url('" + (_vm.$route.path.match('investidores')
                      ? _vm.iconSubItemUserEscuro
                      : _vm.iconSubItemUserClaro) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/administrador/investidores"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('investidores')
                          ? 'color-0e5daf'
                          : 'color-b2d8ff'))},[_vm._v(" investidores ")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 1)?_c('li',{class:("d-flex my-1 " + (_vm.$route.path.match('coordenadores')
                    ? 'active-li-subItem'
                    : null))},[_c('div',{staticClass:"item-drop",style:(("background-image: url('" + (_vm.$route.path.match('coordenadores')
                      ? _vm.iconSubItemUserEscuro
                      : _vm.iconSubItemUserClaro) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/administrador/coordenadores"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('coordenadores')
                          ? 'color-0e5daf'
                          : 'color-b2d8ff'))},[_vm._v(" coordenadores ")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 1)?_c('li',{class:("d-flex my-1 " + (_vm.$route.path.match('professores')
                    ? 'active-li-subItem'
                    : null))},[_c('div',{staticClass:"item-drop",style:(("background-image: url('" + (_vm.$route.path.match('professores')
                      ? _vm.iconSubItemUserEscuro
                      : _vm.iconSubItemUserClaro) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/administrador/professores"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('professores')
                          ? 'color-0e5daf'
                          : 'color-b2d8ff'))},[_vm._v(" professores ")])])],1)]):_vm._e(),(_vm.$store.getters.getPerfil == 1)?_c('li',{class:("d-flex my-1 " + (_vm.$route.path.match('alunos') ? 'active-li-subItem' : null))},[_c('div',{staticClass:"item-drop",style:(("background-image: url('" + (_vm.$route.path.match('alunos')
                      ? _vm.iconSubItemUserEscuro
                      : _vm.iconSubItemUserClaro) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/administrador/alunos"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('alunos')
                          ? 'color-0e5daf'
                          : 'color-b2d8ff'))},[_vm._v(" alunos ")])])],1)]):_vm._e()])]):_vm._e(),(_vm.$store.getters.getPerfil == 1)?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match('unidades-e-instituicoes')
                ? 'active-li-aluno'
                : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('unidades-e-instituicoes')
                  ? _vm.iconUnidadesInstituicoesAzul
                  : _vm.iconUnidadesInstituicoesCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/administrador/unidades-e-instituicoes"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('unidades-e-instituicoes')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("Instituições")])])],1)]):_vm._e(),(
            _vm.$store.getters.getPerfil == 1 || _vm.$store.getters.getPerfil == 2
          )?_c('li',{class:("d-flex my-3 " + (_vm.$route.path.match('meus-dados') ? 'active-li-aluno' : null))},[_c('div',{style:(("background-image: url('" + (_vm.$route.path.match('meus-dados')
                  ? _vm.configuracoesAzul
                  : _vm.configuracoesCinza) + "')"))}),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('router-link',{attrs:{"to":"/meus-dados"}},[_c('span',{class:("text-uppercase " + (_vm.$route.path.match('meus-dados')
                      ? 'color-0e5daf'
                      : 'color-cbccd3'))},[_vm._v("meus dados")])])],1)]):_vm._e(),_c('li',{attrs:{"id":"footer"}},[_c('button',{staticClass:"text-uppercase bg-d42338 color-fff btn-style-default mt-2",on:{"click":function($event){return _vm.desconectar()}}},[_vm._v(" sair ")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo-in-menu d-flex align-items-center justify-content-center"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":"logo.png"}})])])}]

export { render, staticRenderFns }